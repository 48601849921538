.tell-your-friends {
  width: min-content;
  margin: var(--tellFriendsMargin);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  fa-icon {
    font-size: var(--tellFrindsIconsFontSize);
    padding: var(--tellFrindsIconsPadding);
    background: var(--tellFrindsIconsBackground);
    color: var(--tellFriendsIconsColor);
  }
  .title {
    font-size: 16px;
    margin-right: 25px;
  }

  .social-links {
    &,
    li {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    display: inline-flex;
    flex-grow: row wrap;
    justify-content: center;

    a {
      margin: var(--tellFriendsIconsMargin);
      color: var(--tellFriendsIconsColor);
    }

    li:last-child {
      a {
        margin-right: 0;
      }
    }
  }

  .share-text {
    font-weight: bold;
    color: var(--tellFriendsTextColor);
    line-height: 0;
  }

  &.compacted {
    background: var(--brandColor);
    border-radius: 20px;
    padding: 4px 10px;
    will-change: transform;
    transition: all 0.3s ease-in;
    span,
    a,
    fa-icon {
      color: var(--brandColor-contrast, white);
    }

    span {
      font-weight: bold;
      font-size: 15px;
    }
    a {
      transition: all 0.3s ease;
      opacity: 0;
    }
    ul {
      transition: all 0.3s ease-in;
      width: 0;
      overflow: hidden;
    }

    &:hover,
    &:focus,
    &:active {
      ul {
        width: 170px;
      }
      a {
        opacity: 1;
      }
    }
  }
}

.share-icon {
  margin-right: 10px;
}

// ALTERNATIVE DESING

.social-links {
  position: relative;
}

.social-links--button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--social-links--button-width, 40px);
  height: var(--social-links--button-height, 40px);
  font-size: var(--social-links--button-font-size, 30px);
}

.social-links--dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: var(--social-links--dropdown-background, white);
  border-radius: var(--social-links--dropdown-border-radius, var(--borderRadius));
}

.social-links--button:is(:hover, :focus) .social-links--dropdown {
  display: block;
}

.social-links--list {
  display: flex;
  flex-flow: column nowrap;
  row-gap: var(--social-links--dropdown-row-gap, 8px);
  margin: 0;
  padding: 0;
}

.social-links--item {
  list-style: none;
  padding: var(--social-links--item-padding, 12px);
}

.social-links--hyperlink {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--social-links--button-width, 40px);
  height: var(--social-links--button-height, 40px);
  background: var(--social-links--button-background, white);
  color: var(--social-links--button-color, black);
  font-size: var(--social-links--button-font-size, 30px);
}
